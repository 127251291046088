.font-weight-bold {
    font-weight: bold;
}

.d-flex {
    display: flex;
}

.d-flex-between {
    justify-content: space-between;
}

.d-flex-around {
    justify-content: space-around;
}

.d-flex-center {
    justify-content: center;
}

.d-flex-end {
    justify-content: flex-end;
}

.d-flex-items-center {
    align-items: center;
}

.d-flex-wrap{
    flex-wrap:wrap
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.background-white {
    background: #fff;
}

.display-block {
    display: block;
}

.margin-auto {
    margin: 0 auto;
}

.h{
    height: 100vh;
}

.w{
    width:100%;
}
.width-33{
    width:33.33%;
}

.border-radius-y{
    border-radius:50%;
}
.border-glb-b{
    border-bottom: 1px solid #f6f6f6;
}
.border-glb-dashed{
    border-bottom: 1px dashed #cecece;
}
.b-all{
    border:1px solid #f6f6f6;
}