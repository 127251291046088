@for $i from 2 through 1000 {
    .w-#{$i} {
        width: $i + px;
    }
}

@for $i from 1 through 100 {
    .w-#{$i}p {
        width: $i * 1%;
    }
}

@for $i from 2 through 1000 {
    .h-#{$i} {
        height: $i + px;
    }
}

@for $i from 1 through 100 {
    .h-#{$i}p {
        height: $i * 1%;
    }
}

@for $i from 10 through 1000 {
    .lh-#{$i} {
        line-height: $i + px;
    }
}

@for $i from 1 through 300 {
    .f-#{$i} {
        font-size: $i + px !important;
    }
}

@for $i from 1 through 500 {
    .p-#{$i} {
        padding: $i + px;
    }
}

@for $i from 1 through 500 {
    .p-l-#{$i} {
        padding-left: $i + px;
    }
}

@for $i from 1 through 500 {
    .p-r-#{$i} {
        padding-right: $i + px;
    }
}

@for $i from 1 through 500 {
    .p-t-#{$i} {
        padding-top: $i + px;
    }
}

@for $i from 1 through 500 {
    .p-b-#{$i} {
        padding-bottom: $i + px;
    }
}

@for $i from 1 through 500 {
    .m-#{$i} {
        margin: $i + px;
    }
}

@for $i from 1 through 500 {
    .m-l-#{$i} {
        margin-left: $i + px;
    }
}

@for $i from 1 through 500 {
    .m-r-#{$i} {
        margin-right: $i + px;
    }
}

@for $i from 1 through 500 {
    .m-t-#{$i} {
        margin-top: $i + px;
    }
}

@for $i from 1 through 500 {
    .m-b-#{$i} {
        margin-bottom: $i + px;
    }
}

@for $i from 1 through 1000 {
    .border-radius-#{$i} {
        border-radius: $i + px;
    }
}
