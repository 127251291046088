.bg-color {
    color: #009fe8
}

.bg-background {
    background: #009fe8;
}

.color-333 {
    color: #333
}

.color-999 {
    color: #999
}

.color-fff {
    color: #fff;
}

.color-error{
    color:#f56c6c;
}

.back-fff{
    background:#fff;
}

.back-f9f9f9{
    background:#f9f9f9;
}

