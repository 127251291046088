@import '~antd/dist/antd.css';

body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
header,
menu,
section,
p,
input,
td,
th,
ins {
    padding: 0;
    margin: 0;
}

ul,
ol,
li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #666;
}

a:hover {
    color: #666;
    text-decoration: underline;
}

i,
em {
    font-style: normal;
}

input,
textarea,
button,
select,
a {
    outline: none;
    border: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    border: none;
    vertical-align: middle;
}

/* 全局样式 */
body,
textarea,
select,
input,
button {
    color: #333;
    font-family: '微软雅黑';
    background-color: #f5f5f5;
}